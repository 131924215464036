import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "menu", "button" ]

  toggle(event) {
    event.stopPropagation()
    event.preventDefault()

    if (this.buttonTarget.getAttribute('aria-expanded') == "false") {
      this.show()
    } else {
      this.hide(event)
    }
  }

  show() {
    this.buttonTarget.setAttribute('aria-expanded', "true");
    this.menuTarget.hidden = false;
  }

  hide() {
    this.buttonTarget.setAttribute('aria-expanded', "false");
    this.menuTarget.hidden = true;
  }
}
