import { Controller } from "stimulus"
import GLightbox from 'glightbox'

export default class extends Controller {
  connect() {
    const lightbox = GLightbox({
      touchNavigation: true,
      loop: true,
      autoplayVideos: true
    });
  }
}
